import React, { useState, useEffect } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { getImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import parse from "html-react-parser"

import "aos/dist/aos.css"

const textMotion = {
  rest: {
    y: 0,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    y: -25,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
}
const TabServices = ({ title, copy, items, pageName = "" }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  // const [fade, setFade] = useState(false);

  useEffect(() => {
    const AOS = require("aos")
    AOS.init({
      duration: 100,
      offset: 0,
      delay: 10,
      once: false,
      mirror: true,
      easing: "ease",
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  return (
    <>
      <div
        className={`section has-background-bis tab-service-section has-text-white ${pageName} aos-item`}
        data-aos="fade-up"
        data-aos-offset="10"
        data-aos-delay="100"
        data-aos-duration="1000"
      >
        <div className="container">
          <div className="page-content has-text-white">
            <motion.div variants={textMotion}>
              <div className="is-size-para is-size-para-mobile content-block-top has-text-white column is-9">
                <h2 className="is-size-3 is-size-3-mobile has-text-weight-semibold">
                  {title ? parse(title) : "Our services."}
                </h2>
                {copy && <p>{parse(copy)}</p>}
              </div>

              <div className="is-multiline content-padding content-block-bottom column is-12">
                <Tabs
                  selectedIndex={activeIndex}
                  onSelect={index => setActiveIndex(index)}
                  className={"tabs-block columns"}
                  selectedTabClassName="selected"
                >
                  <div className="tab-icons">
                    {items.map((item, index) => (
                      <div
                        key={index}
                        data-aos={
                          "zoom-in"
                          // index === 0
                          //   ? "zoom-in-up"
                          //   : index === 2
                          //   ? "zoom-in-down"
                          //   : "zoom-in"
                        }
                        data-aos-duration="1000"
                        data-aos-delay={
                          "10"
                          // index === 0 ? "600" : index === 1 ? "100" : "1200"
                        }
                        // data-aos-once="true"
                        className={`${
                          index === activeIndex ? "active" : ""
                        } tab-icon-item`}
                        data-aos-offset={
                          "10"
                          // index === 0 ? "200" : index === 1 ? "0" : "0"
                        }
                        onClick={() => setActiveIndex(index)}
                      >
                        <img
                          src={
                            getImage(item.image.localFile).images.fallback.src
                          }
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                  <div className="tabs-lg">
                    <TabList className={"tabs"}>
                      <span className="circle" aria-hidden="true">
                        <span className="icon arrow"></span>
                      </span>
                      {items.map((item, index) => (
                        <Tab key={index} className={"tab-button"}>
                          {parse(item.title)}
                        </Tab>
                      ))}
                    </TabList>

                    {items.map((item, index) => {
                      return (
                        <TabPanel className={`tab-desc`} key={`tab_${index}`}>
                          <div className="content-block is-size-para is-size-para-mobile">
                            <div
                              data-aos-duration="1000"
                              data-aos-offset="10"
                              data-aos-delay="10"
                              data-aos="zoom-in"
                            >
                              <h2 className="is-size-4 is-size-4-mobile has-text-weight-normal">
                                {parse(item.title)}
                              </h2>

                              {item.items.map((item, index) => (
                                <p key={index}>
                                  <strong>{parse(item.title)}</strong> <br></br>
                                  {parse(item.copySeo || "")}
                                </p>
                              ))}
                            </div>
                          </div>
                        </TabPanel>
                      )
                    })}
                  </div>
                </Tabs>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TabServices
