import React, { useEffect } from "react"
import { graphql } from "gatsby"

/* NPM */
import "aos/dist/aos.css"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import HowWeDo from "../components/HowWeDo"
import TabServices from "../components/TabServices"
import IconsContent from "../components/IconsContent"

const ServicesSeo = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")

    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  return (
    <Layout
      showFooter={page.pageSettings.showFooter}
      footerTitle={
        "Ready to transform your content <br> and captivate your audience?"
      }
    >
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />
      <PageWrapper additionalClassName="services-page">
        <PageTitleSection
          title={page.headerSettings.title}
          subtitle={page.headerSettings.subTitle}
          description="Effective SEO is about more than just topping the search rankings; we craft SEO solutions that mesh with your broader business objectives, boosting visibility, captivating the right audience, and transforming them into dedicated clients. This guarantees that your SEO not only enhances your digital presence but also positively impacts your overall business performance and market position."
        />
        {page.ServiceSEOFields.howWeDoItSeo && (
          <HowWeDo {...page.ServiceSEOFields.howWeDoItSeo[0]} />
        )}

        {page?.ServiceSEOFields?.ourServicesSeo && (
          <TabServices {...page?.ServiceSEOFields?.ourServicesSeo} />
        )}

        {page?.ServiceSEOFields?.bottomContentSeo && (
          <IconsContent {...page?.ServiceSEOFields?.bottomContentSeo} />
        )}
      </PageWrapper>
    </Layout>
  )
}

export default ServicesSeo

export const ServicesSeoQuery = graphql`
  query ServicesSeoId(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
      ServiceSEOFields {
        howWeDoItSeo {
          title
          items {
            title
            copy
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        ourServicesSeo {
          title
          copy
          items {
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            items {
              title
              copySeo
            }
          }
        }
        bottomContentSeo {
          title
          icons {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          copy
        }
      }
    }
  }
`
