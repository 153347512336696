import React, { useEffect, useState } from "react"

import Section from "./Section"
import { interpolate } from "flubber"
import {
  motion,
  useMotionValue,
  MotionValue,
  useTransform,
  animate,
} from "framer-motion"
import { getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import "aos/dist/aos.css"
import iconSun from "../assets/icons/sun-pops-4.svg"
import iconPlay from "../assets/icons/play.svg"
import iconLines from "../assets/icons/lines-5.svg"
import iconPen from "../assets/icons/pen-3.svg"

import { mountain1 } from "../utils/paths"
import { mountain2 } from "../utils/paths"
import { mountain3 } from "../utils/paths"
import { mountain4 } from "../utils/paths"

const textMotion = {
  rest: {
    y: 0,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn",
    },
  },
  hover: {
    y: -25,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeOut",
    },
  },
}

//User transform
export const getIndex = (_, index) => index

export function useFlubber(progress, pathImage1) {
  return useTransform(progress, pathImage1.map(getIndex), pathImage1, {
    mixer: (a, b) => interpolate(a, b, { maxSegmentLength: 0.1 }),
  })
}

const paths = [mountain1, mountain2, mountain3, mountain4]

// const colorsMountain = ["#fff200", "#fff200", "#fff200", "#fff200"];

const IconsContent = ({ copy, icons, title }) => {
  //Animatede image
  const [pathIndex, setPathIndex] = useState(0)
  const progress = useMotionValue(pathIndex)
  const [path, setPath] = useState(mountain2)

  useEffect(() => {
    switch (pathIndex) {
      case 1:
        setPath(mountain1)
        break
      case 2:
        setPath(mountain2)
        break
      case 3:
        setPath(mountain3)
        break
      case 4:
        setPath(mountain4)
        break
      // default: setPath(mountain1); break;
    }

    // const animation = animate(progress, pathIndex, {
    //   duration: 0.8,
    //   ease: "easeInOut",
    //   onComplete: () => {
    //     if (pathIndex === paths.length - 1) {
    //       progress.set(0);
    //       setPathIndex(1);
    //     } else {
    //       setPathIndex(pathIndex + 1);
    //     }
    //   }
    // });

    // return () => animation.stop();
  }, [pathIndex])

  //  const progress = useMotionValue(pathIndex);

  //  const fill = useTransform(progress, pathImage1.map(getIndex));
  //  const path = useFlubber(progress, pathImage1);

  //  useEffect(() => {
  //   const animation = animate(progress, pathIndex, {
  //    duration: 0.8,
  //    ease: "easeInOut",
  //    onComplete: () => {
  //     if (pathIndex === pathImage1.length - 1) {
  //      progress.set(0);

  //      setPathIndex(1);
  //     } else {
  //      setPathIndex(pathIndex + 1);
  //     }
  //    },
  //   });

  //   return () => animation.stop();

  //  }, [pathIndex]);

  const mountainViewBox = "0 0 113 114"
  const playViewBox = "1106 353.37 112.882 112.89"
  const linesViewBox = "960 508.37 112.882 112.89"
  const penViewBox = "826 890.37 112.882 112.89"

  return (
    <>
      <Section additionalClasses="section icons-content has-background-bis has-text-white">
        {/* <svg width="113" height="114" viewBox="0 0 113 114">
    <g>
     {path &&
      path.length > 0 &&
      path.map((path, index) => {
       return (
        <g>
         <motion.path
          key={index}
          fill={path.fill}
          d={path.path}
          fillRule="evenodd"
          dataname="Path 433"
         />
        </g>
       );
      })}
    </g>
   </svg> */}
        <div
          className="flex-wrap aos-item"
          data-aos="fade-up"
          data-aos-offset="10"
          data-aos-delay="10"
        >
          <div className="is-size-para is-size-para-mobile title-item has-text-white has-text-weight-normal column is-12">
            <h2 className="is-size-3 is-size-3-mobile has-text-weight-bold">
              {title ? parse(title) : "Content."}
            </h2>
          </div>
          <div className="icons-list column is-3">
            <div
              className="icon-item aos-item"
              data-aos="zoom-in"
              data-aos-offset="10"
              data-aos-duration="50"
              data-aos-delay="10"
            >
              <img
                src={getImage(icons[0].image.localFile).images.fallback.src}
                alt=""
              />
            </div>
            <div
              className="icon-item aos-item"
              data-aos="zoom-in"
              data-aos-offset="10"
              data-aos-duration="250"
              data-aos-delay="20"
            >
              <img
                src={getImage(icons[1].image.localFile).images.fallback.src}
                alt=""
              />
            </div>
            <div
              className="icon-item aos-item"
              data-aos="zoom-in"
              data-aos-offset="10"
              data-aos-duration="450"
              data-aos-delay="30"
            >
              <img
                src={getImage(icons[2].image.localFile).images.fallback.src}
                alt=""
              />
            </div>
            <div
              className="icon-item aos-item"
              data-aos="zoom-in"
              data-aos-offset="10"
              data-aos-duration="650"
              data-aos-delay="40"
            >
              <img
                src={getImage(icons[3].image.localFile).images.fallback.src}
                alt=""
              />
            </div>
          </div>
          <div className="is-size-para is-size-para-mobile content-page has-text-white column">
            {copy && parse(copy)}
          </div>
        </div>
      </Section>
    </>
  )
}

export default IconsContent
